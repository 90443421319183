import { defineNuxtPlugin } from 'nuxt/app';
import {
	EventClient,
	GtagAdapter,
	MoengageAdapter,
	OmnisendAdapter,
	SavyourAdapter,
	StatsigAdapter,
} from '@laam/events';
import type { IAdapter } from '@laam/events';
import { MetaPixelAdapter } from '@laam/events/src/adapters/metapixel.ts';
import { v4 as uuidv4 } from 'uuid';
import type { InboundMarketing } from '~/types/store';
import { useGlobalStore } from '~/stores/global';
import { useCountryCode } from '~/stores/country';

export default defineNuxtPlugin(async () => {
	const { store } = useGlobalStore();
	const inBoundMarketing = store.inbound_marketing as InboundMarketing | null;
	const config = useRuntimeConfig();
	const validAdapters: IAdapter[] = [];
	const { countryCode } = useCountryCode();

	const statsig = new StatsigAdapter();
	const user = localStorage.getItem('logan')
		? JSON.parse(localStorage.getItem('logan')!)
		: null;

	// Reset stable ID if their stable id is empty due to the bug we had
	let stableId = localStorage.getItem('STATSIG_LOCAL_STORAGE_STABLE_ID');
	if (typeof stableId == 'string' && stableId === '') {
		stableId = uuidv4();
		localStorage.setItem('STATSIG_LOCAL_STORAGE_STABLE_ID', stableId);
	}

	await statsig.init({
		sdkKey: config.public.statsigSdkKey,
		environment: config.public.statsigEnvironment,
		countryCode: countryCode,
		user: {
			userID: user ? (user.isSignedIn ? user.loganId : null) : null,
			email: user ? (user.isSignedIn ? user.email : null) : null,
		},
	});

	validAdapters.push(statsig);

	if (inBoundMarketing?.ga) {
		const ga = new GtagAdapter();
		await ga.init({
			tagId: inBoundMarketing.ga.ga_measurement_id,
		});
		validAdapters.push(ga);
	}

	if (inBoundMarketing?.omnisend) {
		const omnisend = new OmnisendAdapter();
		await omnisend.init({
			brandId: inBoundMarketing.omnisend?.brand_id,
		});
		validAdapters.push(omnisend);
	}

	if (inBoundMarketing?.moengage) {
		const moengage = new MoengageAdapter();
		await moengage.init({
			apiKey: inBoundMarketing.moengage.api_key,
			cluster: inBoundMarketing.moengage.cluster,
			debugLogs: inBoundMarketing.moengage.environment === 'development',
		});
		validAdapters.push(moengage);
	}

	const metaPixel = new MetaPixelAdapter();
	const pixelIds = ['2173897539375580'];
	if (inBoundMarketing?.meta_pixel) {
		pixelIds.push(inBoundMarketing.meta_pixel.meta_pixel_id);
	}
	metaPixel.init({
		pixelId: pixelIds,
	});
	validAdapters.push(metaPixel);

	// Savyour Adapter
	if (config.public.savyourAuthKey) {
		const savyour = new SavyourAdapter();
		savyour.init({
			authKey: config.public.savyourAuthKey,
		});
		validAdapters.push(savyour);
	}

	// filter out invalid adapters
	// requestedAdapters.forEach((adapter) => {
	// 	if (adapter === 'omnisend' && inBoundMarketing?.omnisend) {
	// 		const instance = new OmnisendAdapter();
	// 		instance.init({
	// 			tagId: inBoundMarketing?.omnisend?.api_key,
	// 			// cluster: config.public.moengageCluster,
	// 			// debugLogs: false,
	// 		});
	// 		validAdapters.push(instance);
	// 	}
	// });

	const eventClient = new EventClient();
	eventClient.setAdapters(validAdapters);

	return {
		provide: {
			eventClient,
		},
	};
});

declare module 'nuxt/app' {
	interface NuxtApp {
		$eventClient: EventClient;
	}
}
